
    .single_tenant_container{
        padding-top: 70px;
        padding-bottom: 70px;
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    }
    .single_tenant_sec_heading{
        color: var(--Black, #202020);
        font-family: Poppins;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        text-transform: capitalize;
        text-align: center;
    }
    .single_tenant_sec_min_heading{
        color: #171717;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 93.75% */
    text-transform: capitalize;
    }
    .single_tenant_sec_para{
        color: #171717;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 125% */
        text-transform: capitalize;
        text-align: center;
        margin-top: 20px;
    }
    .single_tenant_sec_img{
    margin-top: 30px;
    width: 90%;
    height: auto;
    }
    .single_tenant_sec_box_img{
        margin-top: 30px;
        width: 80%;
        height: auto;
    }
    .single_tenant_sec_box_lifecycle_img{
        margin-top: 30px;
        width: 90%;
        height: auto;
    }
    .single_tenant_sec_box{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .single_tenant_sec_btn{
        color: #F5F5F5;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 11px; /* 68.75% */
text-transform: uppercase;
width: 149px;
height: 45px;
flex-shrink: 0;
border-radius: 100px;
background: #666AE5;
margin-top: 40px;
    }

    /* 1st section padding  */
    .single_tenant_sec{
        padding-top: 90px;
    }
    /* media queriez for 1600px size */
    @media screen and (max-width:1600px) {

        .single_tenant_container{
            padding-top: 65px;
            padding-bottom: 65px;
        }    
    /* 1st section padding  */
        .single_tenant_sec{
            padding-top: 85px;
        }
        
    .single_tenant_sec_heading{
        font-size: 42px;
        line-height: 58px;
    }
    .single_tenant_sec_min_heading{
    font-size: 30px;
    line-height: 28px; /* 93.75% */
    }
    .single_tenant_sec_img{
        margin-top: 38px;
    }
    .single_tenant_sec_para{
        font-size: 22px;
        line-height: 28px; /* 125% */
        margin-top: 18px;
    }
    }

    
    /* media queriez for 1300px size */
    @media screen and (max-width:1300px) {

        .single_tenant_container{
            padding-top: 60px;
            padding-bottom: 60px;
        }    
    /* 1st section padding  */
        .single_tenant_sec{
            padding-top: 80px;
        }
        
    .single_tenant_sec_heading{
        font-size: 40px;
        line-height: 56px;
    }
    .single_tenant_sec_min_heading{
    font-size: 28px;
    line-height: 26px; /* 93.75% */
    }
    .single_tenant_sec_img{
        margin-top: 36px;
    }
    .single_tenant_sec_para{
        font-size: 20px;
        line-height: 26px; /* 125% */
        margin-top: 16px;
    }
    }

    
    /* media queriez for 1000px size */
    @media screen and (max-width:1000px) {

        .single_tenant_container{
            padding-top: 50px;
            padding-bottom: 50px;
        }    
    /* 1st section padding  */
        .single_tenant_sec{
            padding-top: 70px;
        }
        
    .single_tenant_sec_heading{
        font-size: 38px;
        line-height: 54px;
    }
    .single_tenant_sec_min_heading{
    font-size: 26px;
    line-height: 26px; /* 93.75% */
    }
    .single_tenant_sec_img{
        margin-top: 35px;
    }
    .single_tenant_sec_para{
        font-size: 18px;
        line-height: 25px; /* 125% */
        margin-top: 14px;
    }
    }


    
    /* media queriez for 776px size */
    @media screen and (max-width:776px) {

        .single_tenant_container{
            padding-top: 40px;
            padding-bottom: 40px;
        }    
    /* 1st section padding  */
        .single_tenant_sec{
            padding-top: 65px;
        }
        
    .single_tenant_sec_heading{
        font-size: 35px;
        line-height: 52px;
    }
    .single_tenant_sec_min_heading{
    font-size: 24px;
    line-height: 25px; /* 93.75% */
    }
    .single_tenant_sec_img{
        margin-top: 33px;
    }
    .single_tenant_sec_para{
        font-size: 16px;
        line-height: 23px; /* 125% */
        margin-top: 12px;
    }
    }

    

    
    
    /* media queriez for 600px size */
    @media screen and (max-width:600px) {

        .single_tenant_container{
            padding-top: 30px;
            padding-bottom: 30px;
        }    
    /* 1st section padding  */
        .single_tenant_sec{
            padding-top: 60px;
        }
        
    .single_tenant_sec_heading{
        font-size: 25px;
        line-height: 35px;
    }
    .single_tenant_sec_min_heading{
    font-size: 20px;
    line-height: 25px; /* 93.75% */
    }
    .single_tenant_sec_img{
        margin-top: 30px;
        width: 100%;
    }
    .single_tenant_sec_para{
        font-size: 14px;
        line-height: 23px; /* 125% */
        margin-top: 12px;
    }
    }

    