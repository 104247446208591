.Architecture_page {
    .architecture_page_hero_sec {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;

        .architecture_page_hero_sec_headings {
            color: #121212;
            text-align: center;
            font-family: Poppins;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 90px;
            /* 140.625% */
            text-transform: capitalize;
            text-wrap: balance;
            z-index: 20;
        }

        .architecture_page_hero_sec_para {
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 187.5% */
            text-transform: capitalize;
            margin-top: 46px;
            margin-bottom: 38px;
            z-index: 20;
        }

        .architectureVector_img {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 30%;
            z-index: 0;
            height: auto;
        }

        .pink_xxx_img {
            position: absolute;
            bottom: 40px;
            right: 37%;
            width: 2%;
            z-index: 0;
        }

        .architecture_img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 30%;
            z-index: 0;
        }
    }

    .architecture_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 76px;
        padding-bottom: 76px;
        min-height: 100vh;

        .architecture_container_heading {
            color: var(--Black, #202020);
            font-family: Poppins;
            font-size: 44px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            text-transform: capitalize;
            text-align: center;
        }

        .architecture_container_para {
            margin-top: 20px;

            color: #171717;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 125% */
            text-transform: capitalize;
            text-align: center;
        }

        .architecture_container_min_img {
            margin-top: 40px;
            width: 56%;
        }

        .architecture_container_img {
            width: 90%;
            margin-top: 40px;
        }

    }
}

// media queriz for 1600px
@media screen and (max-width:1600px) {
    .Architecture_page {
        .architecture_page_hero_sec {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100vh;

            .architecture_page_hero_sec_headings {
                color: #121212;
                text-align: center;
                font-family: Poppins;
                font-size: 60px;
                font-style: normal;
                font-weight: 700;
                line-height: 80px;
                /* 140.625% */
                text-transform: capitalize;
                text-wrap: balance;
                z-index: 20;
            }

            .architecture_page_hero_sec_para {
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 29px;
                /* 187.5% */
                text-transform: capitalize;
                margin-top: 44px;
                margin-bottom: 35px;
                z-index: 20;
            }

            .architectureVector_img {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 30%;
                z-index: 0;
                height: auto;
            }

            .pink_xxx_img {
                position: absolute;
                bottom: 40px;
                right: 37%;
                width: 2%;
                z-index: 0;
            }

            .architecture_img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 30%;
                z-index: 0;
            }
        }

        .architecture_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 70px;
            padding-bottom: 70px;
            min-height: 100vh;

            .architecture_container_heading {
                color: var(--Black, #202020);
                font-family: Poppins;
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                line-height: 53px;
                text-transform: capitalize;
                text-align: center;
            }

            .architecture_container_para {
                margin-top: 18px;
                color: #171717;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                /* 125% */
                text-transform: capitalize;
                text-align: center;
            }

            .architecture_container_min_img {
                margin-top: 36px;
                width: 56%;
            }

            .architecture_container_img {
                width: 90%;
                margin-top: 36px;
            }

        }
    }
}

// media queriez for 1300px

@media screen and (max-width:1300px) {
    .Architecture_page {
        .architecture_page_hero_sec {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100vh;

            .architecture_page_hero_sec_headings {
                color: #121212;
                text-align: center;
                font-family: Poppins;
                font-size: 50px;
                font-style: normal;
                font-weight: 700;
                line-height: 70px;
                /* 140.625% */
                text-transform: capitalize;
                text-wrap: balance;
                z-index: 20;
            }

            .architecture_page_hero_sec_para {
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 29px;
                /* 187.5% */
                text-transform: capitalize;
                margin-top: 35px;
                margin-bottom: 30px;
                z-index: 20;
            }

            .architectureVector_img {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 30%;
                z-index: 0;
                height: auto;
            }

            .pink_xxx_img {
                position: absolute;
                bottom: 40px;
                right: 37%;
                width: 2%;
                z-index: 0;
            }

            .architecture_img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 30%;
                z-index: 0;
            }
        }

        .architecture_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 65px;
            padding-bottom: 65px;
            min-height: 100vh;

            .architecture_container_heading {
                color: var(--Black, #202020);
                font-family: Poppins;
                font-size: 36px;
                font-style: normal;
                font-weight: 600;
                line-height: 48px;
                text-transform: capitalize;
                text-align: center;
            }

            .architecture_container_para {
                margin-top: 15px;
                color: #171717;
                font-family: Poppins;
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: 25px;
                /* 125% */
                text-transform: capitalize;
                text-align: center;
            }

            .architecture_container_min_img {
                margin-top: 33px;
                width: 56%;
            }

            .architecture_container_img {
                width: 90%;
                margin-top: 33px;
            }

        }
    }
}

// media queriz for 1000px

@media screen and (max-width:1000px) {
    .Architecture_page {
        .architecture_page_hero_sec {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100vh;

            .architecture_page_hero_sec_headings {
                color: #121212;
                text-align: center;
                font-family: Poppins;
                font-size: 50px;
                font-style: normal;
                font-weight: 700;
                line-height: 63px;
                /* 140.625% */
                text-transform: capitalize;
                text-wrap: balance;
                z-index: 20;
            }

            .architecture_page_hero_sec_para {
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 25px;
                /* 187.5% */
                text-transform: capitalize;
                margin-top: 30px;
                margin-bottom: 30px;
                z-index: 20;
            }

            .architectureVector_img {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 33%;
                z-index: 0;
                height: auto;
            }

            .pink_xxx_img {
                position: absolute;
                bottom: 40px;
                right: 37%;
                width: 2%;
                z-index: 0;
            }

            .architecture_img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 33%;
                z-index: 0;
            }
        }

        .architecture_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 60px;
            padding-bottom: 60px;
            min-height: 100vh;

            .architecture_container_heading {
                color: var(--Black, #202020);
                font-family: Poppins;
                font-size: 34px;
                font-style: normal;
                font-weight: 600;
                line-height: 45px;
                text-transform: capitalize;
                text-align: center;
            }

            .architecture_container_para {
                margin-top: 12px;
                color: #171717;
                font-family: Poppins;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 125% */
                text-transform: capitalize;
                text-align: center;
            }

            .architecture_container_min_img {
                margin-top: 28px;
                width: 56%;
            }

            .architecture_container_img {
                width: 90%;
                margin-top: 28px;
            }

        }
    }
}

// mdeia queriez for 776px

@media screen and (max-width:776px) {
    .Architecture_page {
        .architecture_page_hero_sec {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100vh;

            .architecture_page_hero_sec_headings {
                color: #121212;
                text-align: center;
                font-family: Poppins;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 58px;
                /* 140.625% */
                text-transform: capitalize;
                text-wrap: balance;
                z-index: 20;
            }

            .architecture_page_hero_sec_para {
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                /* 187.5% */
                text-transform: capitalize;
                margin-top: 27px;
                margin-bottom: 27px;
                z-index: 20;
            }

            .architectureVector_img {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 33%;
                z-index: 0;
                height: auto;
            }

            .pink_xxx_img {
                position: absolute;
                bottom: 40px;
                right: 37%;
                width: 2%;
                z-index: 0;
            }

            .architecture_img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 33%;
                z-index: 0;
            }
        }

        .architecture_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 50px;
            padding-bottom: 50px;
            min-height: 100vh;

            .architecture_container_heading {
                color: var(--Black, #202020);
                font-family: Poppins;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 40px;
                text-transform: capitalize;
                text-align: center;
            }

            .architecture_container_para {
                margin-top: 9px;
                color: #171717;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 125% */
                text-transform: capitalize;
                text-align: center;
            }

            .architecture_container_min_img {
                margin-top: 23px;
                width: 80%;
            }

            .architecture_container_img {
                width: 100%;
                margin-top: 23px;
            }

        }
    }
}

// media queriez for 600px
@media screen and (max-width:600px) {

    .Architecture_page {
        .architecture_page_hero_sec {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            min-height: 100vh;

            .architecture_page_hero_sec_headings {
                color: #121212;
                text-align: center;
                font-family: Poppins;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
                /* 140.625% */
                text-transform: capitalize;
                text-wrap: balance;
                z-index: 20;
            }

            .architecture_page_hero_sec_para {
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                /* 187.5% */
                text-transform: capitalize;
                margin-top: 20px;
                margin-bottom: 23px;
                z-index: 20;

            }

            .architecture_page_hero_sec_para br {
                display: none;
            }

            .architectureVector_img {
                position: absolute;
                left: 0;
                bottom: 80px;
                width: 40%;
                z-index: 0;
                height: auto;
                display: none;
            }

            .pink_xxx_img {
                position: absolute;
                bottom: 140px;
                right: 7%;
                width: 4%;

                z-index: 0;
            }

            .get_start_btn_wrapper {
                z-index: 20;
            }

            .architecture_img {

                right: 0;
                bottom: 0;
                display: none;
                // display: none;

                z-index: 0;
            }
        }

        .architecture_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 30px;
            padding-bottom: 30px;

            min-height: 100vh;

            .architecture_container_heading {
                color: var(--Black, #202020);
                font-family: Poppins;
                font-size: 25px;
        line-height: 35px;
                font-style: normal;
                text-wrap: balance;
                font-weight: 600;
        
                text-transform: capitalize;
                text-align: center;
            }

            .architecture_container_para {
                font-size: 14px;
                line-height: 23px; /* 125% */
                margin-top: 12px;
                color: #171717;
            }

            .architecture_container_min_img {
                margin-top: 23px;
                width: 100%;
            }

            .architecture_container_img {
                width: 100%;
                margin-top: 23px;
            }

        }
    }
}