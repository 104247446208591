body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  /* font-size: 14px; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.pad-container{
  width: 100vw;
  padding-left: 7.915vw;
  padding-right: 7.915vw;
}
body{
  background-color: #FEF4F9;
}
.get_in_touch_btn{
  display: inline-flex;
  padding: 1.375rem 2.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 6.25rem;
background: #666AE5;

color: #F5F5F5;
font-family: Poppins;
font-size: 1rem;
font-style: normal;
font-weight: 800;
line-height: 0.625rem; /* 62.5% */
text-transform: uppercase;
}

.login_btn{
  color: #202020;
font-family: Poppins;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.primary_btn{
  
}


@keyframes underline-grow {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.hover-link::after, .active-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 4px; /* Increased height */
  width: 100%;
  background-color: #666AE5;
  transition: width 0.3s;
}

.hover-link:hover::after {
  width: 100%;
}

.active-link::after {
  animation: underline-grow 0.5s forwards;
}

.login-banner{
  width: 100%;
  /* height: 1715.589px; */
  background-image: url('Assets/loginBanner.png');
  background-size: cover;
  background-position: top;
}

.contact-banner{
  width: 100%;
  /* height: 1715.589px; */
  background-image: url('Assets/contactBanner.png');
  background-size: cover;
  background-position: top;
}

.form-banner{

  /* height: 1715.589px; */
  background-image: url('Assets/contactVector.png');
  background-size: cover;
  background-position: top;
}


li{
  cursor: pointer;
}

footer img{
  cursor: pointer;
}



/* utils */

.space-container{
  padding-left: 10%;
  padding-right: 10%;
  
}

@media screen and (max-width:1300px){
  .space-container{
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media screen and (max-width:1024px){
  .space-container{
    padding-left: 5%;
    padding-right: 5%;
  }
}@media screen and (max-width:600px){
  .space-container{
    padding-left: 3%;
    padding-right: 3%;
  }
}
button:hover{
opacity: 0.8;
}




.main-container{
  
}


/* In your App.css or another style file */
.slick-prev {
  font-size: 30px;
  color: red;
}

.slick-next {
  font-size: 30px;
  color: blue;
}

.slick-prev:hover,
.slick-next:hover {
  color: green; /* Optional: Hover color */
}


 .aside_container{
display: flex;
justify-content: space-between;
width: 100%;
height: 70vh;
align-items: center;
}

.aside_box{
  width: 50%;
  padding: 0.5rem;
  flex-direction: column;
  gap: 2.25rem;
  display: flex;
}
.right_aside_box{
  width: 50%;
  padding: 0.5rem;
 display: flex;
 align-items: center;
 justify-content: end;
 overflow: hidden;
}
.right_aside_box ul {
  flex-direction: column;
  gap: 2.25rem;
  display: flex;
}
.img_box{
  width: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_box img {
  width: 100%;
}


@media screen and (max-width:900px) {
  
 .aside_container{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70vh;
  align-items: center;
  }
  
  .aside_box{
    width: 50%;
    padding: 0.5rem;
    flex-direction: column;
    gap: 1.25rem;
    display: flex;
    font-size: 1rem;
  }
 
  .right_aside_box{
    width: 50%;
    padding: 0.5rem;
   display: flex;
   align-items: center;
   justify-content: end;
   overflow: hidden;
  }
  .right_aside_box ul {
    flex-direction: column;
    gap: 1.25rem;
    display: flex;
  }
  .img_box{
    width: 50%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img_box img {
    width: 100%;
  }
  
}



@media screen and (max-width:600px) {
  
  .aside_container{
   display: flex;
   justify-content: space-between;
   width: 100%;
   height: 70vh;
   align-items: center;
   flex-direction: column;

   }
   
   .aside_box{
    width: 100%;
     padding: 0.5rem;
     flex-direction: column;
     gap: 1.25rem;
     display: flex;
     font-size: 1rem;
   }
  
   .right_aside_box{
    width: 100%;
     padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: end;
    overflow: hidden;
   }
   .right_aside_box ul {
     flex-direction: column;
     gap: 1.25rem;
     display: flex;
   }
   .img_box{
    width: 100%;
     padding: 0.5rem;
     display: flex;
     align-items: center;
     justify-content: center;
   }
   .img_box img {
     width: 100%;
   }
   
 }