.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    position: fixed;
    background-color: transparent;
    z-index: 50;
    right: 0;
    left: 0;
    }
    
    .header_logo{
        width: 159.512px;
    height: auto;
    flex-shrink: 0;
    }
    .header_left{
        display: flex;
        align-items: center;
        gap: 100px;
    }
    .header_left ul{
        display: flex;
        gap: 57px;
    }
    .header_left ul li{
        color: #202020;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    }
    
    .header_left ul li.active::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 2px;
        background-color: #666AE5;
    }
    
    .header button{
        display: inline-flex;
    padding: 20px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    border-radius: 100px;
    background: #666AE5;
    
    color: #F5F5F5;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 10px; /* 62.5% */
    text-transform: uppercase;
    }
    
    
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 4rem;
        position: fixed;
        background-color: transparent;
        z-index: 50;
        right: 0;
        left: 0;
        transition: background-color 0.3s ease;
      }
      
      .header.scrolled {
        background-color: white;
      }
      
      /* Rest of your CSS */
      
    .header_right_box{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .responsive_menu{
        display: none;
    }
    .mobile_links{
        color: #202020;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
    }
    @media screen and (max-width:1300px) {
        
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 3.8rem;
            
            }
            
            .header_logo{
                width: 140.512px;
            height: auto;
            flex-shrink: 0;
            }
            .header_left{
                display: flex;
                align-items: center;
                gap: 60px;
            }
            .header_left ul{
                display: flex;
                gap: 47px;
            }
            .header_left ul li{
                color: #202020;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            }
            
            .header button{
                display: inline-flex;
            padding: 19px 33px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            border-radius: 100px;
            background: #666AE5;
            
            color: #F5F5F5;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 10px; /* 62.5% */
            text-transform: uppercase;
            }
            
    }
    
    @media screen and (max-width:1024px) {
        .responsive_menu{
            display: flex;
        }
        
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 3.7rem;
            }
            
            .header_logo{
                width: 120.512px;
            height: auto;
            flex-shrink: 0;
            }
            .header_left{
                display: flex;
                align-items: center;
                gap: 60px;
            }
            .header_left ul{
                display: flex;
                gap: 47px;
                display: none;
            }
            .header_left ul li{
                color: #202020;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            }
            
            .header button{
                display: inline-flex;
            padding: 13px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            border-radius: 100px;
            background: #666AE5;
            
            color: #F5F5F5;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 10px; /* 62.5% */
            text-transform: uppercase;
            }
            
    }
    
    
    
    @media screen and (max-width:1024px){
        
    }


    @media screen and (max-width:600px) {
        .header_right_box{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
        }
        .header button{
            display: inline-flex;
        padding: 12px 18px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        
        border-radius: 100px;
        background: #666AE5;
        
        color: #F5F5F5;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: 10px; /* 62.5% */
        text-transform: uppercase;
        }
    }